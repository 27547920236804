<template>
  <div class="d-flex flex-column align-center justify-center fill-height fill-height">
    <h1 class="mb-12 text-center">404 | Page not found</h1>
    <UiBtn color="accent" :to="{name: 'Dashboard'}" width="168" height="36">
      To Dashboard
    </UiBtn>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "NotFoundPage",
  components: {
    UiBtn,
  },
}
</script>
